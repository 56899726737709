<template>
  <div class="page page__bussiness-trip page--bottom-indent">
    <new-business-trip v-if="isNewShown" />
    <business-trip v-else />
  </div>
</template>

<script>
import BusinessTrip from '@/components/business-trip';
import NewBusinessTrip from '@/components/business-trip2';

export default {
  name: 'BusinessTripPage',
  components: {
    BusinessTrip,
    NewBusinessTrip,
  },
  computed: {
    isNewShown() {
      return this.$route?.query?.v === 'new';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
